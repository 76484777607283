<template>
    <!--发布需求-->
    <div class="page">
       
        <div class="demand-content">
            <van-tabs v-model="active" @change="changeTab" style="font-size: 22px">
                <van-tab title="最新">
<!--                    <van-field-->
<!--                            readonly-->
<!--                            clickable-->
<!--                            :value="place"-->
<!--                            name="picker"-->
<!--                            label="地点"-->
<!--                            placeholder="点击选择"-->
<!--                            @click="showPicker = true"-->
<!--                            right-icon="arrow-down"/>-->
                   <van-list
                      @load="loadData()"
                      finished-text="没有更多了"
                      :finished="params.finished"
                      offset="30"
                   >
                      <div class="content-top" v-for="(item,index) in demands" :key="index">
                    <div class="main-top" @click="goDemandDetails(item)">
                      <div class="worker-photo">
                        <img class="createdUserAvatar" style="border-radius: 50%;" v-if="item.type=='RECRUITMENT'" src="@/assets/images/zg.jpg" alt="" />
                        <img class="createdUserAvatar" v-else src="@/assets/images/xq.jpg" style="border-radius: 50%;" alt="" />
                      </div>
                      <div class="con-right">
                        <div class="con-top">
                          <div class="con-title">
                            <span style="color: #E7807D">{{item.type=='RECRUITMENT'?'[招工]':'[需求]'}} </span>{{item.title}}
                          </div>
<!--                          <div class="progress">-->
<!--                            {{item.createdDate}}-->
<!--                          </div>-->
                        </div>
                        <div class="address">
                          <div>
                            {{ item.contactsName.substring(0,1) }}**: {{ item.contactsTel }}
                          </div>
                          <div class="progress">
                            {{item.createdDate}}
                          </div>
                        </div>
                        <div class="con-bottom">
                          <div class="con-money">

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="requirement">
                      要求:{{item.content}}
                    </div>
                    <div class="place">
                            <span>
                                工程地点:{{item.workingPlaceName}}
                            </span>
                      <span style="float: right">{{item.date}}</span>
                    </div>
                  </div>
                   </van-list>
                </van-tab>
                <van-tab title="推荐">
                  <div class="content-top" v-for="(item,index) in keyDemands" :key="index">
                    <div class="main-top" @click="goDemandDetails(item)">
                      <div class="worker-photo">
                        <img class="createdUserAvatar" style="border-radius: 50%;" v-if="item.type=='RECRUITMENT'" src="@/assets/images/zg.jpg" alt="" />
                        <img class="createdUserAvatar" v-else src="@/assets/images/xq.jpg" style="border-radius: 50%;" alt="" />
                      </div>
                      <div class="con-right">
                        <div class="con-top">
                          <div class="con-title">
                            <span style="color: #E7807D">{{item.type=='RECRUITMENT'?'[招工]':'[需求]'}} </span>{{item.title}}
                          </div>

                        </div>
                        <div class="address">
                          <div>
                            {{ item.contactsName }} : {{ item.contactsTel }}
                          </div>
                          <div class="progress">
                            {{item.createdDate}}
                          </div>
                        </div>
                        <div class="con-bottom">
                          <div class="con-money">

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="requirement">
                      要求:{{item.content}}
                    </div>
                    <div class="place">
                            <span>
                                工程地点:{{item.workingPlaceName}}
                            </span>
                      <span style="float: right">{{item.date}}</span>
                    </div>
                  </div>
                </van-tab>
            </van-tabs>
        </div>

        <!-- 地点弹出选择 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :value="place"
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    :default-index="0"
                    cancel-button-text="清除已选"
            />
        </van-popup>
    </div>
</template>


<script>
import * as demandApi from "../../api/demand/index";
import { Dialog,Toast,Notify } from 'vant';
import {findByKey} from "../../api/demand/index";
    export default {
        components: {},
        data() {
            return {
              showFail:false,
              active: 0,
              place: undefined,
              showPicker: false,
              demands: [],
              keyDemands:[],
              columns: ["济南", "高新"],
              params:{
                page: 0,
                size: 10,
                total: 0,
                loading: false,
                finished: false,
                isLoading: false,
                status: undefined
              },
              keyParams:{
                pageNum: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                finished: false,
                isLoading: false,
                status: undefined
              },

            };
        },
        mounted() {
            this.loadData();
            this.loadKeyData();
        },
        methods: {
          changeTab(e){
            console.info("changeTab",e)
            // this.params.status=e==0?undefined:"FAIL"
            // this.loadData();
          },
          async loadData() {
            if (!this.params.isLoading && !this.params.finished) {
              this.params.isLoading=true
              let r = await demandApi.findAll(this.params);
              this.params.total = r.total;
              this.demands = [...this.demands, ...r.content];
              if (this.params.page * this.params.size >= r.totalElements) {
                this.params.finished = true;
              } else {
                this.params.finished = false;
              }
              this.params.isLoading = false;
              this.params.page++
            }
          },
          async loadKeyData() {
            if (!this.keyParams.isLoading && !this.keyParams.finished) {
              this.keyParams.isLoading=true
              let r = await demandApi.findByKey(this.keyParams);
              if (r){
                this.keyParams.total = r.total;
                this.keyDemands = [...this.keyDemands, ...r.content];
                if (this.keyParams.page * this.keyParams.size >= r.totalElements) {
                  this.keyParams.finished = true;
                } else {
                  this.keyParams.finished = false;
                }
                this.keyParams.isLoading = false;
                this.keyParams.page++
              }
            }
          },
          goDemandDetails(item){
            this.$router.push(`demand/`+item.id)
          },
            onConfirm(value) {
                this.place = value;
                this.showPicker = false;

            },
            onCancel() {
                this.place = undefined;
                this.showPicker = false;
            },

        },
    };
</script>

<style lang="less" scoped>
    .demand-content {
        .content-top {
            height: 160px;
            background: #FFFFFF;

            .main-top {
                border-bottom: 1px solid #F7F7F7;
                clear: both;
                display: flex;
                justify-content: space-between;
                padding: 8px;
                margin-top: 8px;
              padding-bottom: 0;
                .worker-photo {
                    height: 70px;
                    width: 60px;
                    text-align: center;

                    img {
                        height: 60px;
                        width: 60px;
                      border-radius: 5px;
                    }
                }

                .con-right {
                    height: 60px;
                    width: 80%;
                    padding: 5px;

                    .con-top {
                        display: flex;
                        justify-content: space-between;

                        .con-title {
                            height: 20px;
                          line-height: 20px;
                            width: 80%;
                            font-size: 18px;
                            color: #212121;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .address {
                      margin-top: 6px;
                        height: 22px;
                        font-size: 15px;
                        color: #8B8D90;
                        display: flex;
                        justify-content: space-between;
                        line-height: 22px;
                      .progress {
                        //font-size: 10px;
                        color: #3B83CE;
                      }
                    }

                    .con-bottom {
                        height: 30px;
                        display: flex;
                        justify-content: space-between;

                        .con-money {
                            color: #E7807D;
                        }
                    }
                }

            }
        }

        .requirement {
            padding: 6px 10px 10px 10px;
            font-size: 15px;
            color: #212121;
            border-bottom: 1px solid #F7F7F7;
            height: 32px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .place {
            padding: 6px;
            font-size: 14px;
            color: #C3C3C3;
            height: 15px;
        }

    }


</style>
<style>
.van-tab__text--ellipsis {
  font-size: 18px;
}
</style>
